.not-found {
    padding-top: 250px;
}

.not-found h1 {
    padding-top: 50px;
    font-size: 70px;
}

.not-found h2 {
    font-size: 30px;
}

.not-found a {
    font-size: 20px;
    cursor: pointer;
}
