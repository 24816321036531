:root{
	--color-primary:#FFFFFF;
	--color-primary-2:#3B3B3B;
	--color-primary-3:#64C3FE;
	--color-primary-4:#E3E0F3;
}
body, p{
	color:#5e6576;
	font:400 1rem/1.625rem "Open Sans",sans-serif
}
h1{
	color:#222;
	font-weight:700;
	font-size:2.5rem;
	line-height:3.25rem;
	letter-spacing:-.4px
}
h2{
	color:#222;
	font-weight:700;
	font-size:2rem;
	line-height:2.625rem;
	letter-spacing:-.4px
}
h3{
	color:#222;
	font-weight:700;
	font-size:1.75rem;
	line-height:2.25rem;
	letter-spacing:-.2px
}
h4{
	color:#222;
	font-weight:700;
	font-size:1.5rem;
	line-height:2rem;
	letter-spacing:-.2px
}
h5{
	color:#222;
	font-weight:700;
	font-size:1.25rem;
	line-height:1.625rem
}
h6{
	color:#222;
	font-weight:700;
	font-size:1rem;
	line-height:1.375rem
}
.top-padding {
	padding-top: 150px;
}
.p-large{
	font-size:1.3rem;
	line-height:2rem
}
.p-small{
	font-size:.875rem;
	line-height:1.5rem
}
.li-space-lg li{
	margin-bottom:.5rem
}
a{
	color:#5e6576;
	text-decoration:underline
}
a:hover{
	color:#5e6576;
	text-decoration:underline
}
.section-title{
	display:inline-block;
	margin-bottom:.75rem;
	padding: .3rem 1.75rem;
	border-radius:30px;
	background-color:var(--color-primary-3);
	color:var(--color-primary);
	font-weight:600;
	font-size:1.1rem
}
.btn-solid-lg{
	display:inline-block;
	padding:1.625rem 2.625rem 1.625rem 2.625rem;
	border:1px solid var(--color-primary-3);
	border-radius:30px;
	background-color:var(--color-primary-3);
	color:var(--color-primary);
	font-weight:600;
	font-size: 1.2rem;
	line-height:0;
	text-decoration:none;
	transition:all .2s
}
.btn-solid-lg:hover{
	background-color:transparent;
	color:var(--color-primary-3);
	text-decoration:none
}
.header{
	padding-bottom:5rem;
	text-align:center;
	background-color:var(--color-primary)
}
.header .h1-large{
	margin-bottom:1.25rem;
	color: #5E6576;
	font-size:2rem
}
.header .p-large{
	margin-bottom:2.75rem
}
.header .btn-solid-lg{
	display:block;
	margin-bottom:1.75rem
}
.header .text-container{
	margin-bottom:5rem
}
.basic-1{
	padding-top:5rem;
	padding-bottom:5rem
}
.basic-1 .image-container{
	margin-bottom:4rem;
	margin-top:3rem
}
.basic-1 h2{
	margin-bottom:1.875rem;
}
.basic-1 h2 span{
	font-weight:400;
	font-size:2rem
}

.basic-1 p {
	font-size:1.3rem;
}

.basic-1 p:last-of-type{
	margin-bottom:2rem
}
.form-1{
	padding-top:9.25rem;
	padding-bottom:8.5rem;
	text-align:center
}
.form-1 .h2-heading{
	margin-bottom:1.125rem;
	text-align:center;
	font-size: 2.7rem;
	line-height: 3.5rem;
}
.form-1 .h2-heading span{
	font-weight:400
}
.form-1 .p-heading{
	margin-bottom:.375rem
}
.form-1 .list-unstyled{
	margin-bottom:3.5rem
}
.form-1 .list-unstyled .fas{
	color:var(--color-primary-3)
}
.form-1 li {
	font-size:1.3rem;
}
.copyright{
	padding-top:2rem;
	border-top:1px solid #d0dae2;
	text-align:center;
	place-items:center;
	height:70px;
}
.copyright a{
	text-decoration:none
}

.copyright p {
	font-size:1.3rem;
}

.copyright .list-unstyled,.copyright .statement{
	opacity:.7
}
.copyright .list-unstyled li{
	margin-bottom:.75rem
}

.social-icon img {
	width: 50px;
}

@media (min-width:768px){
	.header{
		 padding-top:9rem
	}
	.header .h1-large{
		 font-size:2.2rem;
		 line-height:2.5rem
	}
	.form-1 .list-unstyled li{
		 display:inline-block;
		 margin-right:1rem
	}
}
@media (max-width:992px){
	.top-padding {
		padding-top: 150px;
	}
	.image-remove{
		 display:none
	}
	.header{
		 padding-bottom:0
	}
	.basic-1{
		 padding-top:0;
		 padding-bottom:0
	}
	.container-about{
		 padding-top:0;
		 padding-bottom:0
	}
	.text-container .btn-solid-lg{
		 margin-left:10%;
		 margin-right:10%;
	}
	.basic-1 .text-container{
		 text-align:left;
	}
	.container-about .card-about{
		 text-align:center
	}
}
@media (min-width:992px){
	.h2-heading{
		 width:35.25rem;
		 margin-right:auto;
		 margin-left:auto
	}
	.p-heading{
		 width:46rem;
		 margin-right:auto;
		 margin-left:auto
	}
	.header{
		 text-align:left
	}
	.header .text-container{
		 margin-bottom:0;
		 text-align: left;
	}
	.header .btn-solid-lg{
		 margin-right:1.5rem;
		 margin-bottom:0
	}
	.header .btn-solid-lg{
		 display:inline-block
	}
	.basic-1 .image-container{
		 margin-bottom:0
	}
	.basic-1 .text-container{
		 margin-top:4.25rem;
		 text-align:left
	}
	.basic-1 h2{
		 width: 23rem;
		 font-size: 2rem
	}
	.copyright .list-unstyled li{
		 display:inline-block;
		 margin-right:1rem
	}
}
@media (min-width:1200px){
	.container{
		 max-width:1140px
	}
	.header{
		 overflow:hidden;
		 padding-bottom:11.5rem
	}
	.header .image-container{
		 position:relative
	}
	.header .image-container img{
		 position:absolute;
		 top:-80px;
		 left:10px;
		 width:840px;
		 max-width:none
	}
	.basic-1 .image-container{
		 margin-right:3.5rem
	}
	.basic-1 .text-container{
		 margin-top:5rem;
		 margin-left:2.75rem
	}
	.basic-2 .text-container{
		 padding:8rem 12rem
	}
	.form-1 form{
		 width:860px;
		 margin-right:auto;
		 margin-left:auto
	}
}
.container-about{
	padding-top:10rem;
	justify-content:center;
	align-items:center;
	margin:0 auto;
	display:flex;
	gap:30px;
	flex-wrap:wrap
}
.card{
	width:350px;
	background-color:#fff;
	position:relative;
	box-shadow:0 3px 8px -1px rgba(34,60,80,.2)
}
.card__fanny{
	width:350px;
	background-color:#fff;
	position:relative;
	box-shadow:0 3px 8px -1px rgba(34,60,80,.2)
}
.card-photo{
	width:350px;
	height:235px;
	overflow:hidden
}
.card-photo img{
	transition:1s;
	display:block;
	width:100%;
	height:100%;
	object-fit:cover
}
.card-title{
	position:absolute;
	top:220px;
	left:45px;
	width:260px;
	display:flex;
	flex-direction:column;
	align-items:center;
	background-color:#fff;
	padding:5px 0 15px 0
}
.card-name{
	font-size:1.4rem;
	-webkit-animation:tracking-in-expand .7s cubic-bezier(.215,.61,.355,1) both;
	animation:tracking-in-expand .7s cubic-bezier(.215,.61,.355,1) both
}
.card-info{
	display:flex;
	flex-direction:column;
	gap:12px;
	padding:70px 44px 30px 44px
}
.card-about{
	font:400 1rem/1.625rem "Open Sans",sans-serif
}
.card-email{
	font-weight:700;
	position:relative;
	right:0;
	bottom:0;
	left:0;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center
}
.card-email a{
	position:relative;
	font-weight:400;
	text-decoration:none
}
.card-profession h4{
	font-size:1rem;
	font-weight:700;
	position:relative;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:-20px
}
.card-profession{
	font-weight:700;
	-webkit-animation:tracking-in-expand .7s cubic-bezier(.215,.61,.355,1) both;
	animation:tracking-in-expand .7s cubic-bezier(.215,.61,.355,1) both
}
.social-box{
	position:relative;
	right:0;
	bottom:0;
	left:0;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center
}
.social-icon{
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
	width:50px;
	height:50px;
	margin:0 .5rem;
	border-radius:50%;
	cursor:pointer;
	font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size:2.5rem;
	text-decoration:none
}
.fa-researchgate{
	color:#0cb
}
.social-icon:hover{
	color:#fff
}
.social-icon:hover .tooltip{
	visibility:visible;
	opacity:1;
	transform:translate(-50%,-150%)
}
.social-icon:active{
	box-shadow:0 1px 3px rgba(0,0,0,.5) inset
}
.social-icon--linkedin{
	background:#006599;
	color:#fff
}
.social-icon--linkedin .tooltip{
	background:#006599;
	color:currentColor
}
.social-icon--linkedin .tooltip:after{
	border-top-color:#006599
}
.social-icon--researchGate{
	background:#fff;
	color:#fff
}
.social-icon--researchGate .tooltip{
	background:#00CABA;
	color:currentColor
}
.social-icon--researchGate .tooltip:after{
	border-top-color:#00CABA;
}
.tooltip{
	display:block;
	position:absolute;
	top:0;
	left:50%;
	padding:.8rem 1rem;
	border-radius:40px;
	font-size:.8rem;
	font-weight:700;
	opacity:0;
	pointer-events:none;
	text-transform:uppercase;
	transform:translate(-50%,-100%);
	transition:all .3s ease;
	z-index:1
}
.tooltip:after{
	display:block;
	position:absolute;
	bottom:1px;
	left:50%;
	width:0;
	height:0;
	content:"";
	border:solid;
	border-width:10px 10px 0 10px;
	border-color:transparent;
	transform:translate(-50%,100%)
}
