.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
    stroke: #4DBEDF !important;
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
    stroke: #e9e3e3 !important;
}

@media screen and (max-width: 575px) {
    .half-circle-chart {
        margin-bottom: -25%;
    }
}