.flex-column {
    background-color: transparent !important;
}

.nav-item {
    text-align: left;
    margin-left: 0px !important;
}

.nav-link {
    /* font-size: 16px !important; */
}

.navbar-theme-primary {
    background-color: #262B40 !important;
}