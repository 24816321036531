.pagination-dashboard {
	width: 100%;
}

.dashboard-option {
	margin-top: 20px !important;
	padding-top: 50px !important;
}

.dashboard-reset {
	top: 473px !important;
}

@media screen and (max-width: 480px) {
	.dashboard-reset {
		top: 670px !important;
	}
}