.navbar-dashboard {
    background-color: #F5F8FB !important;
}

.navbar-dashboard body {
    background-color: #F5F8FB !important;
}

.show {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}
