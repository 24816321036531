.ssogis-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ssogis-options div {
    margin-right: 5px;
}

.ssogis-options-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    z-index: 99999;
}

.ssogis-options-selected {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 10px;
}

.ssogis-options-selected-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border: 1px solid #DEDEDF;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 2px;
    transition: all 5s ease-in-out;
}

.ssogis-options-table {
    display: inline-block;
    border: 1px solid #DEDEDF;
    border-radius: 15px;
    padding: 5px;
    font-size: 10px;
}

.ssogis-options-selected-item-text {
    margin-right: 5px;
}

.dropDownWrapper {
    position: relative;
    display: inline-block;
    text-align: left;
}

.dropDownButton {
    display: inline-flex;
    width: 250px;
    border-radius: 0.25rem;
    border: 1px solid #DEDEDF;
    padding: 0.55rem 1rem;
    background-color: #ffffff;
    font-weight: 500;
    line-height: 1.5;
    color: #DEDEDF;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dropDownButton:hover {
    border-color: #b4b4b4;
}

.dropDownButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.dropDownIcon {
    position: absolute;
    color: black;
    right: 10px;
}

.dropDownOptions {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    background-color: #ffffff;
}

.dropDownOptionsItemWrapper {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.dropDownOptionsItem {
    display: flex;
    flex-direction: row;
}

.dropDownWrapperChecked {
    width: 175px !important;
}

.ssogis-checkbox {
    margin-top: 5px;
}

.gray-c {
    border: 1px solid gray;
    background-color: #e6e6e6;
    color: gray;
}

.green-c {
    border: 1px solid green;
    background-color: #b3ffb3;
    color: green;
}

.blue-c {
    border: 1px solid blue;
    background-color: #b3d9ff;
    color: blue;
}

.red-c {
    border: 1px solid red;
    background-color: #ffb3b3;
    color: red;
}

.orange-c {
    border: 1px solid #ff9500;
    background-color: #ffd9b3;
    color: #ff9500;
}

.lightblue-c {
    border: 1px solid rgb(96, 149, 255);
    background-color: #b3e6ff;
    color: rgb(96, 149, 255);
}

.ssogis-options-selected-item-count {
    font-weight: bold;
    margin-right: 5px;
}

.ssogis-button {
    background-color: #64C3FE !important;
    color: white !important;
}

.ssogis-expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
}

.ssogis-expanded-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 80%;
    max-width: 1200px;
    height: 80%;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
    z-index: 102;
}

.tableExpanded {
    text-align: left;
}

.ssogis-expanded-content-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.ssogis-expanded-content-options-dropdown {
    width: 400px !important;
    margin: 0 auto;
}

.ssogis-expanded-content-options-label {
    position: absolute;
    left: 0;
}

.ssogis-expanded-content-options-label-wrapper {
    width: 400px;
    margin: 0 auto;
    position: relative;
    height: 30px;
}

.ssogis-expanded-content-options-textarea {
    width: 400px;
    height: 100px;
    margin-top: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 1px solid #dededf;
    padding-bottom: 65px;
    padding-left: 5px;
}

.ssogis-expanded-content-options-button {
    width: 400px;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ssogis-expanded-content-checkbox {
    text-align: left;
    width: 100%;
}

.ssogis-expanded-content-possible-matches {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.ssogis-expanded-content-possible-matches-column {
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 20px;
}

.ssogis-expanded-content-possible-matches-column td {
    white-space: normal;
    word-wrap: break-word;
}

.table-expanded {
    min-height: 150px !important;
}

@media screen and (max-width: 1350px) {
    .ssogis-options-wrapper {
        transform: scale(0.6);
        width: 170%;
        margin-left: -34%;
        position: relative;
    }
}