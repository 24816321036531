.error-graph .ct-series-a .ct-line {
    stroke: #ff7f0e !important; /* orange */
}

.error-graph .ct-series-b .ct-line {
    stroke: #2ca02c !important; /* green */
}

.error-graph .ct-series-c .ct-line {
    stroke: #9467bd !important; /* purple */
}

.error-graph .ct-series-d .ct-line {
    stroke: #aec7e8 !important; /* brown */
}

.error-graph .ct-series-e .ct-line {
    stroke: #e377c2 !important; /* pink */
}

.error-graph .ct-series-f .ct-line {
    stroke: #1f77b4 !important; /* blue */
}

.error-graph .ct-series-g .ct-line {
    stroke: #bcbd22 !important; /* yellow-green */
}

.error-graph .ct-series-h .ct-line {
    stroke: #17becf !important; /* light-blue */
}

.error-graph .ct-series-i .ct-line {
    stroke: #1f77b4 !important; /* blue */
}

.error-graph .ct-series-j .ct-line {
    stroke: #d62728 !important; /* red */
}

.error-graph .ct-series-k .ct-line {
    stroke: #2ca02c !important; /* light-blue */
}

.error-graph .ct-series-l .ct-line {
    stroke: #ffbb78 !important; /* light-orange */
}

.error-graph .ct-series-m .ct-line {
    stroke: #98df8a !important; /* light-green */
}