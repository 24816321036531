.pagination-list {
	display: inline-block;
	position: relative;
}

.pagination a {
	color: white;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
}

.selected {
	background-color: #64C3FE;
	color: white;
	border-radius: 5px;
}

.pagination li:last-child,
.pagination li:first-child {
	background-color: #64C3FE;
	border-radius: 5px;
}

.pagination a:hover:not(.selected) {
	background-color: #ddd;
	border-radius: 5px;
}

.pagination ul {
	list-style-type: none;
	overflow: hidden;
}
	
.pagination {
	margin: 0 auto;
	width: fit-content;
	width: -moz-fit-content;
	width: -webkit-fit-content;
}

.pagination li {
	display: inline-block;
}

.pagination li:first-child {
	position: absolute;
	left: 10px;
}

.pagination li:last-child {
	position: absolute;
	right: 10px;
}

th.description {
	width: 40%;
}

th.case-id {
	width: 7%;
}

th.county {
	width: 7%;
}

th.region {
	width: 8%;
}

th.roadway {
	width: 12%;
}

th.direction {
	width: 7%;
}

th.date {
	width: 12%;
}

.database-table {
	width: 100%;
	max-width: 1200px;
	cursor: text;
}

.database-table tr:nth-child(2n) {
	background-color: #f3f3f3;
}

.database-table tr:hover {
	background-color: #64C3FE;
	color: white;
	-webkit-transition: background-color 200ms linear;
	-ms-transition: background-color 200ms linear;
	transition: background-color 100ms linear;
}

.database-table th:not(:hover) {
	background-color: white;
	color: black;
}

.database-table th:hover {
	background-color: white;
	color: black;
}

.secondary-table {
	width: 100%;
	cursor: text;
	table-layout: fixed;
}

.secondary-table tr:nth-child(2n) {
	background-color: #f3f3f3;
}

.pagination-container {
	margin-top: 30px;
	padding-top: 10px;
	border: 1px solid #DDDDDD;
}

.pagination-controls {
	padding-top: 10px;
	padding-bottom: 10px;
}

.expand-header {
	width: 40px;
}

.description-expand {
	padding: 0 5%;
}

.direction-expand {
	padding-bottom: 20px;
}

.case_id-expand {
	padding-top: 20px;
}

.date-data {
	text-align: center !important;
	vertical-align: middle !important;
}

thead {
	font-size: 15px;
}

.mobile-page-count {
	font-size: 16px;
	position: absolute;
	bottom: 48px;
    left: 50%; 
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 2000px) {
	/* table {
		font-size: 20px;
		max-width: 1600px;
	} */
}

@media screen and (min-width: 750px) {
	.secondary-table {
		display: none;
	}

	.mobile-page-count {
		display: none;
	}

	.entires-text {
		padding-top: 20px;
		font-size: 16px;
	}
}


@media screen and (max-width: 750px) {
	.main-table {
		display: none;
	}

	.pagination li {
		display: none;
	}

	.pagination a {
		color: white;
	}

	.pagination {
		margin-bottom: 20px;
	}
	
	.pagination li:first-child {
		display: block;
		border-radius: 5px;
		outline: 1px solid #64C3FE;
		margin-top: -20px;
		left: 10%;
		background-color: #64C3FE;
		color: white;
		padding: 0px 4px;
	}
	
	.pagination li:last-child {
		display: inline-block;
		border-radius: 5px;
		outline: 1px solid #64C3FE;
		margin-top: -20px;
		right: 10%;
		background-color: #64C3FE;
		color: white;
		padding: 0px 16px;
	}

	.pagination a:hover:not(.selected) {
		background-color: #64C3FE;
	}

	.entires-text {
		padding-top: 20px;
	}
}

@media screen and (max-width: 405px) {
	.expand-header {
		width: 15px;
	}
}
