.hide-icon  {
    display: flex !important;
}

@media screen and (max-width: 576px) {
    .hide-icon  {
        display: none !important;
    }

    .cards-mobile {
        width: 100%;
        text-align: center !important;
    }
}