@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');

.navbar{
    width: 100% !important;
    background-color: white !important;
}

.navbar-brand {
    height: 90px;
    display: flex;
  	justify-content: center;
  	align-items: center;
    transition: all 0.25s ease-in-out;
}

.navbar-brand img{
    height: 60px !important;
    width: auto;
}

.nav{
    margin-left:auto;
    font-size: 15px !important;
    background-color: white !important;
}

.navbar-box_shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1) !important;
}

#navbar-shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .05)
}

.nav-item {
    cursor: pointer !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    padding-top: 10px;
    text-decoration: none !important;
    margin-left: 10px;
}

#responsive-navbar-nav {
    margin-right: -120px !important;
}

.center-margin {
    padding-right: 9px;
    padding-left: 9px !important;
}

.nav-item:hover {
    color: #64C3FE !important;
}

.nav-item a{
    color: #5E6576 !important;
    text-decoration: none !important;
}

.nav-item a:hover{
    color: #64C3FE !important;
}

.navbar-light .navbar-nav .nav-link {
    /* color: #5E6576 !important; */
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
    color: #64C3FE !important;
}

.nav-button {
    background-color: #64C3FE !important;
    border-radius: 20px !important;
    color: white !important;
    margin-left: 15px !important;
    padding: 0 20px !important;
    font-size: 18px !important;
    width: 130px !important;
    height: 40px !important;
}

.nav-button:hover {
    background-color: white !important;
    border: 1px solid #64C3FE !important;
    color: #64C3FE !important;
}

.show {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.records-navigation {
    margin-top: 2.13px !important;
    font-size: 18.3px !important;
    color: black !important;
}

@media screen and (max-width: 1500px) {
    #responsive-navbar-nav {
        margin-right: -50px !important;
    }    
}

@media screen and (max-width: 1250px) {
    #responsive-navbar-nav {
        margin-right: 0px !important;
    }    
}

@media screen and (max-width: 992px) {
    .nav-button {
        padding: 10px 20px !important;
        width: auto !important;
        text-align: center !important;
        margin-right: 30px !important;
    }

    .center-margin {
        padding-right: 0px;
        padding-left: 0px !important;
    }

    .navbar-nav {
        line-height: 30px;
        padding-left: 20px !important;
    }
}
